
:root {
    --bar-width: 3em;
    --bar-height: .35em;
    --burger-margin: 8px;
    --burger-height: 30px;
    --burger-width: calc(30px *  1.41421356237);
}

/* NavBar styling */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #264653;
    color: white;
}

.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.navbar-links li {
    list-style: none;
}

.navbar-links li a {
    text-decoration: none;
    color: white;
    padding: 1rem;
    display: block;
}

.navbar-links li:hover {
    background-color: #2A9D8F;
    border-radius: 35px;    
}

/* Hamburger Bar styling */
.burger-menu {
    position: absolute;
    top: var(--burger-margin);
    right: var(--burger-margin);
    z-index: 2;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    height: var(--burger-height);
    width: 4em;
}

.burger-bar{
    width: 50px;
    height: var(--bar-height);
    border-radius: .5em;
    background-color: white;
}

@media (max-width: 800px){
    .burger-menu {
        display: flex;
    }

    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links ul {
        width: 100%;
        flex-direction: column;
    }

    .navbar-links li {
        text-align: center;
    }

    .navbar-links li a {
        padding: .5em 1em;
    }
    
    .navbar-links.hidden {
        display: none;
    }

    .navbar-links.visible {
        display: flex;
        width: 100%;
    }

}

/* ---- Animations ---- */
/*clicked*/

.burger-bar.clicked:nth-child(1) {
    width: var(--burger-width);
    transform-origin: left center;
    transform: rotate(45deg);
    translate: 10px calc(var(--bar-height) / -2);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2) {
    transform: scale(0.1);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3) {
    width: var(--burger-width);
    transform-origin: left center;
    transform: rotate(-45deg);
    translate: 10px calc(var(--bar-height) / 2);
    transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
    width: var(--bar-width);
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) .5s;
}


/* Company logo */
#companyLogo {
    width: 50px;
    height: auto;
}
